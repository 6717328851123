import React from "react";

function ContactInformation() {
  return (
    <section id="contact-information">
      <h1>Contact</h1>
      <h2>Contact Information</h2>
    </section>
  );
}

export default ContactInformation;
