import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Nodes from "../Nodes";
import "./About.css";

/** COMPONENTS */
import SidewaysText from "../SidewaysText/SidewaysText";

const About_Section = styled.article`
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--main-font-color);
  position: relative;
`;
const About_Container = styled.section`
  width: 90%;
  display: flex;
  flex-flow: column wrap;
`;
const About_Title_Section = styled.section`
  display: flex;
  justify-content: end;
`;
const About_Title_Shadow = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 149px;
  line-height: 190px;
  letter-spacing: 0.065em;
  color: rgba(255, 255, 255, 0.18);
  text-transform: uppercase;
`;
const About_Title = styled.span`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 78px;
  line-height: 100px;
  letter-spacing: 0.065em;
  color: #ffffff;
  position: relative;
  top: -130px;
  text-transform: lowercase;
`;

const About_Me_Section = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 40px;

  #about-me-p {
    box-shadow: 14px 14px black;
  }

  #about-me-p:hover {
    box-shadow: 14px 14px 10px black;
  }
`;
const About_Me_P = styled.p`
  max-width: max-content;
  min-width: 700px;
  height: min-content;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.07em;
  background: #4b2e34;
  padding: 2em;
  border: 1px solid white;

  margin-left: 175px;

  transition: box-shadow 0.5s ease;

  &:hover {
    box-shadow: 14px 14px 24px black;
  }

  ul li {
    margin-left: 30px;
  }
`;

function About() {
  const [displayDefinition, setDisplayDefinition] = useState("none");

  function DisplayRestructuringDefinition() {
    return (
      <div
        className="restructuring_definition"
        style={{ display: displayDefinition }}
      >
        <span
          className="close_definition"
          onClick={() => setDisplayDefinition("none")}
        >
          X
        </span>
        <dfn>Restructuring</dfn>
        <p>
          A reorganization of a company with a view to achieving greater
          efficiency and profit, or to adapt to a changing market.
        </p>
      </div>
    );
  }

  return (
    <About_Section id="about-section">
      <DisplayRestructuringDefinition />

      {/* <Nodes top={180} left={57} scale="0.5" scaleX={"-1"} /> */}
      <About_Container id="about-container">
        <About_Title_Section
          id="about-title-section"
          className="about-title-section-main"
        >
          <About_Title_Shadow id="about-title-section">
            about
            <About_Title id="about-title">about</About_Title>
          </About_Title_Shadow>
        </About_Title_Section>
        {/* 
        <Nodes
          top={175}
          left={35}
          scale="0.5"
          scaleX={"-1"}
          position={"absolute"}
        /> */}

        <About_Me_Section id="about-me-section">
          <About_Me_P id="about-me-p">
            <h3 style={{ textDecoration: "underline" }}>About Nate</h3>
            <br />
            <br />
            It's been nearly a year since my last update to the site. Work has
            kept me busy, like most people. My recent position as a Business
            development representative brought satisfaction, but not the kind of
            deep fulfillment I crave. <br />
            <br />
            Why? 80% of my energy goes into software development. I'm constantly
            <span className="hi-light"> learning</span>, which fuels my{" "}
            <span className="hi-light">passion</span> for the field. However,
            the recent{" "}
            <i
              onMouseEnter={() => setDisplayDefinition("block")}
              className="restructuring"
            >
              restructuring
            </i>{" "}
            at my previous company – where we sold cybersecurity, AI-powered
            security and transformation services –{" "}
            <i>meant my team was let go</i>. <br />
            <br />
            While unfortunate, it's an opportunity to pursue a role that aligns
            better with my goals.
            <br /> <br />
            Expect website updates soon, including projects and maybe even blog
            posts on my interests. I'm passionate about creating learning
            applications that can benefit both myself and others.
            <br />
            <br />
            Thanks,
            <br />
            <br />
            -- Nate
            {/*           
            Hello There 👋🏾, Well, it's been almost a year since i've updated this website.
            I've been working in a job industry that is not suited for me but i've been 
            taking in a lot and working on Dedicated full stack web developer with a{" "}
            <span className="hi-light">passion</span> for building innovative
            and <span className="hi-light">user-friendly</span> web
            applications. Proven ability to work with a variety of technologies,
            including HTML, CSS, <span className="hi-light">JavaScript</span>,
            MySQL, and MongoDB. Strong problem-solving and analytical skills.
            Ability to work independently and as part of a team. <br /> */}
          </About_Me_P>

          <SidewaysText text="About me" />
        </About_Me_Section>
      </About_Container>
    </About_Section>
  );
}

export default About;
